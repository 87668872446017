.header-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.1s ease-in-out;
}

.text-decoration-none {
  text-align: center;
}

.navbar-wrapper .logo-img {
  height: 3rem;
}

.navbar-wrapper .nav-links a {
  color: rgb(51, 51, 51);
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Microsoft YaHei';
  transition: color 0.3s;
}

.navbar-wrapper .nav-links a:hover {
  color: rgb(153, 122, 92);
}

.nav-gray {
  background-color: #f0f2f5;
}

.rockscan {
  color: rgb(153, 122, 92);
}

.geo_clips,
.home,
.home-faq {
  background-color: #22447b;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  border: none;
  border-radius: 30px;
  padding: 12px 21px;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.geo_clips:hover,
.home:hover,
.home-faq:hover {
  background-color: #345a8b;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.home,
.home-faq {
  padding: 12px 32px;
}

.home-faq {
  padding: 12px 40px;
}

.rockscan {
  color: #666;
}

/* Maximum width of 768 pixels, which covers most tablet devices. */

@media screen and (max-width: 768px) {
  .navbar-wrapper {
    padding: 1rem;
  }

  .home,
  .home-faq,
  .geo_clips {
    padding: 6px 23px;
    margin-top: -7px;
    font-size: 14px;
    flex-grow: 1;
  }

  .navbar-wrapper .logo-img {
    height: 2.5rem;
  }

  .navbar-wrapper .nav-links {
    display: none;
  }

  .navbar-wrapper .nav-links.mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .navbar-wrapper .nav-links.mobile a {
    margin: 0 5px;
  }
}

/* Mobile Styles */
@media screen and (max-width: 480px) {
  .navbar-wrapper {
    padding: 7px;
    padding-left: 1px;
  }

  .home,
  .home-faq,
  .geo_clips {
    padding: 5px 5px;
    font-size: 14px;
  }

  .navbar-wrapper .logo-img {
    height: 2.5rem;
  }

  .navbar-wrapper .nav-links {
    display: none;
  }

  .navbar-wrapper .nav-links.mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .navbar-wrapper .nav-links.mobile a {
    margin: 0 5px;
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .home-faq {
    display: none;
  }
}

.toggle-button {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
}

.toggle-button .bar {
  height: 3px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
}

@media (max-width: 800px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .toggle-button {
    display: flex;
  }

  .navbar-links {
    display: none;
    width: 100%;
  }

  .navbar-links ul {
    width: 100%;
    flex-direction: column;
  }

  .navbar-links ul li {
    text-align: center;
  }

  .navbar-links ul li a {
    padding: 0.5rem 1rem;
  }

  .navbar-links.active {
    display: flex;
  }
}

.center-links {
  justify-content: center;
}

body {
  padding-top: 5em; /* Adjust this value based on the height of your navbar */
}

/* ROCK CONTAINER */
.rock-container {
  margin: 2rem auto;
  max-width: calc(100% -80px);
}

.rock-card {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
  gap: 2rem;
}

.article-image {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

article-image img {
  max-width: 100%;
  height: auto;
  border-radius: 20px 20px 0 0;
}

.article-card {
  background: white;
  border-radius: 20px;
  transition: 0.3s;
}

.article-card:hover {
  box-shadow: 0 4px 20px 0 rgba(34, 68, 123, 0.2);
  cursor: pointer;
}

.article-content {
  padding: 2rem;
}

.article-content .card-category {
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 600;
  color: #6b82a7;
  display: block;
  text-decoration: none;
}

.article-content .card-title {
  margin: 1rem 0;
  color: #22447b;
}

.article-content .rock-description {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #6b82a7;
  margin: 0;
}

/* Making IMAGE ZOOM */

.image-container {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.card-image {
  transition: transform 1s;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.card-image:hover {
  transform: scale(1.5);
}

.card-image:not(:hover) {
  transition: transform 1s 0.3s;
  transform: scale(1);
}

.card-actions {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.learn-more-button {
  background-color: #345a8b;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-weight: bold;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  text-transform: uppercase;
}

.learn-more-button:hover {
  background-color: #2b4c78;
}

.privacy-policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.privacy-policy-container h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.privacy-policy-container h3 {
  font-size: 20px;
  margin-top: 20px;
}

.privacy-policy-container ul {
  list-style-type: disc;
  margin-left: 20px;
}

.privacy-policy-container p {
  line-height: 1.5;
  margin-bottom: 15px;
}

.privacy-policy-container a {
  color: #007bff;
}

.privacy-policy-container a:hover {
  text-decoration: underline;
}

/* Terms */
.terms-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.terms-container h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.terms-container h3 {
  font-size: 20px;
  margin-top: 20px;
}

.terms-container ul {
  list-style-type: disc;
  margin-left: 20px;
}

.terms-container p {
  line-height: 1.5;
  margin-bottom: 15px;
}

/* FINISHED IMAGE ZOOM */

@media screen and (min-width: 768px) {
  .rock-container {
    max-width: 608px;
  }
  .article-card {
    display: grid;
    grid-template-rows: 220px 1fr;
  }
}

@media screen and (min-width: 1280px) {
  .rock-container {
    max-width: 1156px;
  }
}

/* FINISHED ROCK CONTAINER */

/* .searchbar-container {
    position: fixed;
    width: 60%
  } */

.search-bar {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 20px;
  background-color: #fff;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.search-bar:focus {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.search-bar::placeholder {
  color: #999;
}

.search-bar:hover,
.search-bar:focus {
  background-color: #fff;
}

.search-bar:hover::placeholder,
.search-bar:focus::placeholder {
  color: #ccc;
}

.search-bar-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #999;
  cursor: pointer;
}

.search-bar-icon:hover {
  color: #333;
}

.search-wikipedia-btn {
  position: absolute;
  top: 4.2em;
  /* right: 10em; */
  right: 157px;

  height: 45px;
  display: inline-block;
  font-size: 16px;
  color: #fff;
  background-color: rgb(173, 142, 112);
  text-decoration: none;
  border-radius: 6px;
  padding: 8px 16px;
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  margin-top: 45px;
  margin-right: -5px;
}

.search-bar {
  border: 1px solid rgba(153, 122, 92, 0.7);
  border-radius: 10px;
}

.vk-btn {
  display: inline-block;
  font-size: 1.2rem;
  color: #fff;
  text-decoration: none;
  border-radius: 6px;
  padding: 8px 16px;
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}

/* For Mobile Style */

@media (max-width: 480px) {
  .rock-container {
    margin: 20px 3px;

    min-height: auto;
  }

  .search-wikipedia-btn {
    top: 12.4em;
    margin-right: -125px;
    /* right: 0.5em; */
    margin-top: 13px;
    font-size: 8px;
    width: 80px;
  }

  .vk-btn {
    font-size: 6px;
    padding: 4px 8px;
  }
}

/* GeoWonderCSS */

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 80px;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.card {
  max-width: 345px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border-radius: 8px;
  overflow: hidden;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.card-content {
  padding: 24px;
}

.image-container {
  height: 200px;
  overflow: hidden;
  border-radius: 8px 8px 0 0; /* Add border radius to the top corners */
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px 8px 0 0; /* Add border radius to the top corners */
}

.card-title {
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  color: #333; /* Change the color of the title */
  font-size: 18px; /* Adjust the font size of the title */
}

.card-description {
  font-style: italic;
  line-height: 1.6;
  text-align: justify;
  color: #333;
  font-size: 16px;
  margin-top: 10px;
}

.card-actions {
  display: flex;
  justify-content: center;
}

.card-actions button {
  margin: 0 8px;
}

/* Share-Popup-Icons */

.share-popup-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.share-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ---------------------- */

@keyframes waterCircle {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}

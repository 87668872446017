.faqWrapper{
    height: 13.13rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6.25rem;
    font-size: 2.63rem;
    font-family: -apple-system,BlinkMacSystemFont,"Microsoft YaHei";
    font-weight: 500;
    background-color: #f0f2f5;
    margin-bottom: 20px;
}
.accordianBlockWrapper .blockTitle{
    color: rgb(153, 122, 92);
    font-size: 1.88rem;
    font-family: -apple-system,BlinkMacSystemFont,"Microsoft YaHei";
    font-weight: 500;
}
.accordianWrapper .accordianHead{
    padding: 12px 40px 12px 16px;
    background-color: #ebf4ff;
    cursor: pointer;
}
.accordianWrapper .icon{
    color: #abb3bd;
    font-size: 30px;
}
.accordianWrapper .title{
    font-size: 1.5rem;
    font-family: -apple-system,BlinkMacSystemFont,"Microsoft YaHei";
    color: rgba(0,0,0,.85);
}
.accordianWrapper .content{
    font-size: 1.13rem;
    color: #666;
    white-space: pre-wrap;
    margin-left: 0.7rem;
}
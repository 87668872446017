.identifyRockWrapper{
    background-color: #f8f9fa;
    padding: 50px 0;
}
.identifyRockWrapper .title{
    color: #000;
    font-size: 2.63rem;
    line-height: 3.13rem;
    font-family: -apple-system,BlinkMacSystemFont,"Microsoft YaHei";
    font-weight: 500;
}
.identifyRockWrapper .description{
    color: #666;
    font-size: 1.25rem;
    margin-top: 2.38rem;
    font-family: -apple-system,BlinkMacSystemFont,"Microsoft YaHei";
}
.bannerWrapper {
  min-height: 100vh;
  background-image: url('../../assets/images/home_back.webp');
  background-repeat: no-repeat;
  background-position: right -52px top 0;
  background-size: contain;
  padding: 8rem 0;
}
.bannerWrapper .bannerTitle {
  color: rgb(153, 122, 92);
  font-family: -apple-system, BlinkMacSystemFont, 'Microsoft YaHei';
  font-weight: 700;
  font-size: 2.625rem;
}
.bannerWrapper .bannerSubtitle {
  font-family: -apple-system, BlinkMacSystemFont, 'Microsoft YaHei';
  font-weight: 700;
  font-size: 2.625rem;
  color: #333741;
  letter-spacing: 0;
  line-height: 3.125rem;
}

.bannerSubtitle {
  font-family: -apple-system, BlinkMacSystemFont, 'Microsoft YaHei';
  font-weight: 700;
  font-size: 2.625rem;
  color: #333741;
  letter-spacing: 0;
  line-height: 3.125rem;
}

.bannerWrapper .bannerDescription {
  font-family: -apple-system, BlinkMacSystemFont, 'Microsoft YaHei';
  font-weight: 400;
  font-size: 1.375rem;
  color: #666;
  letter-spacing: 0;
  margin-bottom: 1rem;
}
.bannerWrapper .bannerSubDescription {
  font-family: -apple-system, BlinkMacSystemFont, 'Microsoft YaHei';
  font-weight: 400;
  font-size: 0.875rem;
  color: #666;
  letter-spacing: 0;
  margin-bottom: 2.88rem;
}
.downloadSection .qrCode img {
  height: 150px;
  width: 150px;
  object-fit: contain;
}
.downloadSection .qrCodeInfo {
  font-family: -apple-system, BlinkMacSystemFont, 'Microsoft YaHei';
  font-weight: 400;
  font-size: 0.75rem;
  color: #333;
  letter-spacing: 0;
  text-align: center;
  margin: 0;
  left: 0;
  right: 0;
  bottom: -45px;
}

.downloadSection .appStore img {
  height: 60px;
  width: 185px;
  object-fit: contain;
}

.bannerTitle {
  /* Add desired styles for the title */
  font-size: 2.5rem;
  font-weight: bold;
  text-transform: uppercase;
}

.titleText {
  /* Add desired styles for the main title text */
  /* color: #333; */
  color: rgb(153, 122, 92);
}

.titleHighlight {
  /* Add desired styles for the highlight text */
  color: #666;
}

.show {
  /* Add animation styles for the show animation */
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.bannerTitle {
  animation: fadeIn 1.5s ease-in-out;
}

.learnButton:hover {
  background-color: #345a8b !important;
  color: #fff !important;
}

.zoomImage {
  transition: transform 0.7s;
}

.zoomImage:hover {
  transform: scale(1.2);
}

/* Styles for mobile devices with screen width up to 480px */
@media only screen and (max-width: 480px) {
  /* Add your CSS styles here for mobile devices */
  .box_card {
    padding: 20px 40px;
  }
}

@media (max-width: 767px) {
}

/* Styles for mobile devices with screen width between 481px and 767px */
@media only screen and (min-width: 481px) and (max-width: 767px) {
  /* Add your CSS styles here for mobile devices */
}

/* Styles for mobile devices with screen width between 768px and 1023px */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* Add your CSS styles here for mobile devices */
}

/* Styles for mobile devices with screen width of 1024px and above */
@media only screen and (min-width: 1024px) {
  /* Add your CSS styles here for mobile devices */
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 80px;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.card {
  max-width: 345px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border-radius: 8px;
  overflow: hidden;
}

/* Remarkable button */
.card {
  position: relative;
}

.mark {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 60px;
  background-color: #f50057;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.mark.clicked {
  background-color: #d50000;
}

.mark:hover {
  background-color: #ff4081;
}

.mark.clicked:hover {
  background-color: #b71c1c;
}

.card:hover .mark {
  background-color: #ff4081;
}

.card:hover .mark.clicked {
  background-color: #b71c1c;
}

.mark:before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 16px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.mark.clicked:before {
  transform: scale(0.8);
}

/* Finished here Mark Button */

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.card-content {
  padding: 24px;
}

.image-container {
  height: 200px;
  overflow: hidden;
  border-radius: 8px 8px 0 0; /* Add border radius to the top corners */
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px 8px 0 0; /* Add border radius to the top corners */
}

.card-title {
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  color: #333; /* Change the color of the title */
  font-size: 18px; /* Adjust the font size of the title */
}

.card-description-wonder {
  font-style: italic;
  line-height: 1.6;
  text-align: justify;
  color: #333;
  font-size: 16px;
  margin-top: 10px;
}

.card-actions {
  display: flex;
  justify-content: center;
}

.card-actions button {
  margin: 0 8px;
}

/* ---------------------- */

/* GeoWonder.Title */

.header {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://images.fineartamerica.com/images/artworkimages/mediumlarge/3/geowonder-philip-kuntz.jpg'); /* Replace with your background image URL */
  background-size: cover;
  background-position: center;
  opacity: 0.7; /* Adjust the opacity as desired */
  z-index: -1;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .header {
    display: none; /* Hide the background image on mobile devices */
  }
}

/* Add the animated fun_fact section */

.card-fun-fact {
  margin-top: 10px;
  font-size: 13px;
  font-style: italic;
  color: #666;
  opacity: 0;
  transition: opacity 0.3s;
  line-height: 20px;
}

.card:hover .card-fun-fact {
  opacity: 1;
}

.fun-fact-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  animation: rotate 3s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
